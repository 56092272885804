import React from 'react'
import Img from 'gatsby-image'

import { HBox, Wrapper } from 'src/ui/layout'
import { Hero } from 'src/ui/organisms'
import { Body, Header } from 'src/ui/typography'
import {
  CULTURE,
  ENVIRONMENT,
  INFRASTRUCTURE,
  MORE_REASONS,
  SIGHTSEEINGS,
  TRAVEL,
  WEATHER,
} from './constants'

type Props = {
  images: TImages
}

export const Reasons: React.FC<Props> = ({ images }) => (
  <>
    <Wrapper>
      <Header size="h4" as="h2">
        Пять причин жить в&nbsp;Калининграде
      </Header>
    </Wrapper>
    <HBox />
    <Hero
      background={images['climat']}
      title="1 Комфортный климат и погода"
      titleSize="h5"
      textHeight={224}
      objectPosition="bottom center"
    >
      <HBox height={24} />
      <Body>{WEATHER}</Body>
    </Hero>
    <HBox height={48} />
    <Hero
      background={images['city']}
      title="2 Качество инфраструктуры и сервиса "
      titleSize="h5"
      textHeight={224}
    >
      <HBox height={24} />
      <Body>{INFRASTRUCTURE}</Body>
    </Hero>
    <HBox height={48} />
    <Hero
      background={images['europe']}
      title="3 Природа и экология"
      titleSize="h5"
      textHeight={224}
    >
      <HBox height={24} />
      <Body>{ENVIRONMENT}</Body>
    </Hero>
    <HBox height={48} />
    <Hero
      background={images['culture']}
      title="4 Культурная жизнь"
      titleSize="h5"
      textHeight={224}
    >
      <HBox height={24} />
      <Body>{CULTURE}</Body>
    </Hero>
    <HBox height={48} />
    <Hero
      background={images['castel']}
      title="5 Море, средневековые рыцарские замки, старинные кирхи, вековые аллеи"
      titleSize="h5"
      textHeight={260}
    >
      <HBox />
      <Body>{SIGHTSEEINGS}</Body>
    </Hero>
    <HBox height={24} />
    <Wrapper>
      <Img fluid={images['museum']} />
    </Wrapper>
    <HBox height={80} />

    <Hero
      noPointerEvents
      videoType="rutube"
      videoId="0fed414f9a2fad26e48fb431d012fbec"
      title={MORE_REASONS}
    >
      <HBox />
    </Hero>
  </>
)
